<template>
  <div class="page-wrapper">
    <div class="title-content">
      <BreadCrumb>
        <el-breadcrumb-item :to="{ name: 'homePage' }">首页</el-breadcrumb-item>

        <el-breadcrumb-item> 印花税测算</el-breadcrumb-item>
      </BreadCrumb>
      <div class="main-top">
        <MainHeader>
          <template slot="title">数据上传</template>
        </MainHeader>

        <div class="placeholder"></div>
      </div>
    </div>
    <div class="section-box">
      <SimpleTable :zq="zq" :templateID="templateID" :program-id="programId"></SimpleTable>

      <div class="footer">
        <button class="btn btn_back btn-confirm" @click="btn_back">
          返回
        </button>
        <button
          class="btn btn-primary btn-confirm"
          @click="handleBtnSaveClick"
          v-if="type==1&&$route.params.from!=='readonly'"
        >
          保存
        </button>
      </div>
    </div>

  </div>
</template>

<script>
import BreadCrumb from "../../../components/BreadCrumb";
import MainHeader from "../../components/MainHeader";
import SaFooter from "../../Test/UploadTable/components/SaFooter";
import SaSteps from "../../components/SaSteps";
import {createStampTaxInspect} from "../../../api/yinhuashui";
import SimpleTable from "../../components/SimpleTable";

export default {
  name: "HeTongTaiZhang",
  components: {
    BreadCrumb,
    MainHeader,
    SaSteps,
    SimpleTable
  },
  computed:{
    programId(){
      return this.$route.params.programId
    }
  },
  props:{
    zq:[String,Number],
    templateID:[String]
  },
  data() {
    return {
      steps: ['基础信息', '合同台账上传', '印花税申报表上传', '提交成功'],
      stepActive: 0,

    }
  },
  methods: {
    btn_back() {
      this.$router.push({
        path: this.$route.query.redirectTo,
      });
    },
  }
}
</script>

<style lang="stylus" scoped>
.page-wrapper {
  padding-bottom: 84px;

  .title-content {
    // width: 1260px;
    height: 96px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px -1px 0px 0px rgba(238, 238, 238, 1);
  }

  .main-top {
    display: flex;
    justify-content: space-between;
  }

  .section-box {
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.6);
    padding-bottom 50px

    .title {
      color: rgba(0, 0, 0, 0.8);
      line-height: 54px;
      font-size: 18px;
      font-weight: 600;
      border-bottom: 1px solid #eeeeee;
    }

    .field {
      margin: 16px 0 0 40px;
      display flex
      align-items center;
    }

    .field:before {
      content: "*";
      color: red;
      position: relative;
      right: 4px;
      top: 1px;
    }

    .label {

      width 100px
    }

    .input {
      width: 40%;
    }

  }
  .footer {
    text-align: center;
    padding: 30px 0 30px;

    .tip {
      color: #F13A4C;
    }

    .btn-confirm {
      font-size: 18px;
      padding: 5px 7px;
      margin-top: 10px;
      width: 200px;
    }

    .btn_back {
      padding: 5px 7px;
      color: #007AFF;
      border-radius: 8px;
      border: 1px solid #ccc;
      margin-right: 25px;
    }
  }

}
</style>
